import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Collapsible from 'react-collapsible'

import * as PurchaseActions from '../../../../actions/PurchaseActions'

import DiscountPartnerForm from './DiscountPartnerForm'

import SvgImage from '../../components/SvgImage'
import iconEdit from 'Assets/images/svg-on-react/icon-pencil.svg'

class DiscountPartnerSection extends Component {
  state = {
    showForm: !this.props.discountCode && !this.props.partnerCard
  }

  handleEdit = () => {
    const { discountCode, partnerCard, localePrefix } = this.props

    if (partnerCard) {
      this.props.actions.updatePartnerCard(null)
      this.setState({ showForm: true })
    } else if (discountCode) {
      this.props.actions.updateDiscountCode(null)

      fetch(`${localePrefix}/remove_discount_code`, {
        method: 'get',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      }).then(
        () => {
          this.setState({ showForm: true })
        }
      )
    }
  }

  validateCallback = () => {
    this.setState({ showForm: false })
  }

  render () {
    const { discountCode, partnerCard } = this.props
    const { showForm } = this.state

    return (
      <div className='purchase-summary__block  purchase-summary__block--discount-partner row'>
        <FormattedMessage id='purchase.discount_partner.title'>
          {(message) => (
            <Collapsible trigger={message}
              triggerClassName='purchase-summary__collapsible-block' triggerOpenedClassName='purchase-summary__collapsible-block'>
              {showForm &&
                <DiscountPartnerForm validateCallback={this.validateCallback} />
              }
              {!showForm && discountCode &&
                <div className='purchase-summary__block--flex'>
                  <div className='purchase-summary__info-block'>
                    <p>
                      <FormattedMessage id='purchase.discount_partner.attributes.discount_name' />
                      {' : '}
                      {discountCode.name}
                    </p>
                    <p>
                      <FormattedMessage id='purchase.discount_partner.attributes.discount_code' />
                      {' : '}
                      {discountCode.code}
                    </p>
                  </div>
                  <div onClick={this.handleEdit} data-automation-id='purchase-summary__discount-code-edit'>
                    <SvgImage svg={iconEdit} className='svg-image--clickable' />
                  </div>
                </div>
              }
              {!showForm && partnerCard &&
                <div className='purchase-summary__block--flex'>
                  <div className='purchase-summary__info-block'>
                    <p>
                      <FormattedMessage id='purchase.discount_partner.attributes.partner_name' />
                      {' : '}
                      {partnerCard.name}
                    </p>
                    <p>
                      <FormattedMessage id='purchase.discount_partner.attributes.partner_number' />
                      {' : '}
                      {partnerCard.cardNumber}
                    </p>
                  </div>
                  <div onClick={this.handleEdit} data-automation-id='purchase-summary__loyalty-card-edit'>
                    <SvgImage svg={iconEdit} className='svg-image--clickable' />
                  </div>
                </div>
              }
            </Collapsible>
          )}
        </FormattedMessage>
      </div>
    )
  }
}

const mapStateToProps = ({ purchase: { discountCode, partnerCard }, resources: { localePrefix } }) => ({
  discountCode, partnerCard, localePrefix
})

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...PurchaseActions }, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscountPartnerSection))
