import React, { Component } from 'react'
import { connect } from 'react-redux'
import { persistStore } from 'redux-persist'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from '../../components/Loader'
import { loadStripe } from '@stripe/stripe-js'
import { datalayerPushGTM, datalayerPush, datalayerPushTransaction } from 'Helpers/application'
import { moveToConfirmationPage, translatedErrorMessage } from 'Helpers/purchaseTunnel'

import RegistrationBreadcrumb from '../../Authentication/Registration/components/RegistrationBreadcrumb'
import ParkOfferSummary from '../ParkOfferSummary'

import AccountSection from './AccountSection'
import TravelInformationsSection from './TravelInformationsSection'
import DiscountPartnerSection from './DiscountPartnerSection'
import PaymentSection from './PaymentSection'
import { Warning } from './Warning'

import { amountWithoutTax, clearPreviousEcommerceObject, gaUserTags } from '../../../../libs/google_analytics_helpers'
import { Info3dSecureDialog } from 'Components/Info3dSecureDialog'
import { OnPurchase3dSecureErrorDialog } from 'Components/OnPurchase3dSecureErrorDialog'
import { OnRegistration3dSecureErrorDialog } from 'Components/OnRegistration3dSecureErrorDialog'
import { SummaryReassurancePanel } from './SummaryReassurancePanel'
import { canEditTravelInfoInitial, scrollToElementWithFooterAdjustment } from 'Helpers/form'
import { bookingDuplicateCheck } from 'Helpers/api'
import { DuplicateBookingModal } from '../DuplicateBookingModal'
import SvgImage from 'Components/SvgImage'
import LogoSvg from 'ClientAssets/images/onepark-header-logo.svg'

class Summary extends Component {
  storagePrefix = this.props.offerBuilder.id

  isMultipleEntriesParamSet = () => {
    const valueFromStorage = sessionStorage.getItem(`${this.storagePrefix}-isMultipleEntriesOption`)
    if (valueFromStorage !== null) return (valueFromStorage === 'true')

    const urlParams = new URLSearchParams(location.search)
    return urlParams.get('multiple_entries') === 'true'
  }

  locallyStored = () => {
    return {
      acceptTerms: sessionStorage.getItem(`${this.storagePrefix}-acceptTerms`) === 'true',
      professionalUse: sessionStorage.getItem(`${this.storagePrefix}-professionalUse`) === 'true',
      isMultipleEntriesOption: this.isMultipleEntriesParamSet(),
      offerAmount: parseFloat(sessionStorage.getItem(`${this.storagePrefix}-offerAmount`)) || 0
    }
  }

  state = {
    ...this.locallyStored(),
    showPaymentForm: !this.props.user.billingIdentity,
    userConsent: this.props.userConsent,
    purchaseError: false,
    errorWithDateRedirect: false,
    threedsError: false,
    loading: false,
    travelInfoEditing: canEditTravelInfoInitial(this.props.travelInfos, this.props.mandatoryInfos),
    stripe: null,
    warningVisible: false,
    summaryError: '',
    highlightTerms: false,
    stripeCompleted: false,
    stripeError: false
  }

  travelInfoSectionRef = React.createRef()
  paymentSectionRef = React.createRef()
  travelFormRef = React.createRef()
  duplicateBookingModalRef = React.createRef()
  footerRef = React.createRef()
  stripeErrorRef = React.createRef()
  threedsErrorRef = React.createRef()

  sectionRefs = {
    travelInformationSection: this.travelInfoSectionRef,
    paymentSection: this.paymentSectionRef
  }

  protectFromReload = (name, value) => {
    sessionStorage.setItem(`${this.props.offerBuilder.id}-${name}`, value.toString())
  }

  travelInfoEditModeSwitcher = (value) => {
    this.setState({ travelInfoEditing: value })
  }

  pushGaEvent () {
    try {
      const { offerBuilder } = this.props
      const park = offerBuilder.park || {}
      const amounts = offerBuilder.amount || {}
      const vatPercent = parseFloat(park.vat)
      const optionPrice = offerBuilder.multipleEntries && this.state.isMultipleEntriesOption
        ? parseFloat(offerBuilder.multipleEntries.optionPrice)
        : 0.0
      const value = amounts.totalWithoutOptions
        ? amountWithoutTax(parseFloat(amounts.totalWithoutOptions + optionPrice), vatPercent)
        : undefined
      const serviceFee = amounts.serviceFee ? amountWithoutTax(amounts.serviceFee, vatPercent) : undefined
      const price = amounts.bookingAmount
        ? amountWithoutTax(parseFloat(amounts.bookingAmount) + optionPrice, vatPercent)
        : undefined
      const place = amounts.place || {}
      const categories = place && place.categories ? place.categories : []
      const placeId = place.id ? place.id.toString() : undefined
      const gaEvent = {
        event: 'begin_checkout',
        ...gaUserTags(),
        ecommerce: {
          item_list_id: placeId,
          item_list_name: place.name,
          currency: 'EUR',
          value,
          sf_pa: serviceFee,
          payment_type: 'credit_card',
          items: [{
            item_id: park.id.toString(),
            item_name: park.seoName,
            item_type: offerBuilder.offerType,
            item_brand: 'onepark',
            item_variant: park.covered,
            price,
            quantity: 1,
            quantity_hours: amounts.gaQuantity,
            item_category: categories[0],
            item_category2: categories[1],
            item_category3: categories[2],
            item_category4: categories[3],
            item_list_id: placeId,
            item_list_name: place.name
          }]
        }
      }
      clearPreviousEcommerceObject()
      window.dataLayer.push(gaEvent)
    } catch (error) {
      if (!(error instanceof TypeError)) { // Network errors
        Sentry.captureException(error)
      }
    }
  }

  checkDuplicateBooking = () => {
    const { localePrefix, offerBuilder: { park: { id: parkId } }, travelInfos: { license_plate: licensePlate } } = this.props
    const url = `${localePrefix}/bookings/check_duplicated?park_id=${parkId}&license_plate=${licensePlate}`
    const switchOffLoading = () => this.setState({ loading: false })
    const setDuplicateType = (type) => this.setState({ duplicateType: type })

    bookingDuplicateCheck(url, switchOffLoading, setDuplicateType, this.completeBooking)
  }

  componentDidMount = async () => {
    datalayerPush({ event: 'page_name', page_name: 'purchase_summary' })
    datalayerPush({
      event: 'page_view',
      ep_page_type_level1: 'booking_summary_and_payment',
      ep_page_type: 'ecommerce',
      ep_site: window._oneparkDatas.ep_site,
      ep_page_name: document.getElementsByTagName('title')[0].innerText,
      ep_logged_status: window._oneparkDatas.user_signed_in,
      up_user_id: window._oneparkDatas.up_user_id,
      up_language: window._oneparkDatas.up_language,
      up_locale: window._oneparkDatas.up_locale
    })
    datalayerPushGTM('hitVirtualPageView')
    this.pushGaEvent()

    const stripe = await loadStripe(this.props.stripePublicKey, { apiVersion: '2022-11-15' })
    this.setState({ stripe })
  }

  highlightSection = (section, messageId) => {
    scrollToElementWithFooterAdjustment(this.sectionRefs[section].current, this.footerRef.current)
    if (!messageId) return

    this.toggleWarning(true)
    this.setState({ summaryError: messageId })
  }

  toggleWarning = (value) => {
    this.setState({ warningVisible: value })
  }

  highlightMissingTerms = () => {
    this.setState({ highlightTerms: true })
  }

  handleSubmitPurchase = async event => {
    const { acceptTerms, travelInfoEditing, showPaymentForm } = this.state
    if (event) { event.preventDefault() }

    if (!acceptTerms) { return this.highlightMissingTerms() }

    const travelInfoIsValid = this.travelFormRef.current && this.travelFormRef.current.allFieldsAreValid()

    if (this.travelFormRef.current && ((travelInfoEditing && travelInfoIsValid) || !travelInfoIsValid)) {
      this.highlightSection('travelInformationSection', 'purchase.purchase_summary.warning_vehicle_travel')
      this.setState({ travelWarning: true })
      scrollToElementWithFooterAdjustment(this.travelFormRef.current, this.footerRef.current)
      return
    }

    if (showPaymentForm) {
      this.setState({ paymentWarning: true })
      this.highlightSection('paymentSection', 'purchase.purchase_summary.warning_payment')

      const stripeSubmitButton = document.getElementById('payment-details-submit-button')
      if (!this.state.stripeCompleted && stripeSubmitButton) { stripeSubmitButton.click() }

      return
    }

    this.toggleWarning(false)
    this.setState({ loading: true })
    await this.checkDuplicateBooking()
  }

  completeBooking = async () => {
    const { userConsent, mobilePurchaseTunnelPaymentInside, history, offerBuilder, beginDate, endDate, renewalDate, travelInfos,
      discountCode, localePrefix, user, partnerCard, type } = this.props

    if (!user.billingIdentity && !mobilePurchaseTunnelPaymentInside) {
      history.push({ pathname: 'payment-informations', search: `?offer_id=${offerBuilder.id}` })
      window.scrollTo(0, 0)
      this.setState({ loading: false })
      return
    }
    const res = await fetch(`${localePrefix}/purchases/create_react`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        offer_id: offerBuilder.id,
        ...travelInfos,
        discount_code: discountCode ? discountCode.code : null,
        partner_loyalty_number: partnerCard ? partnerCard.cardNumber : null,
        partner: partnerCard ? partnerCard.id : null,
        multiple_entries: this.isMultipleEntriesParamSet(),
        personal_data_com: userConsent,
        price: offerBuilder.amount.bookingAmount,
        begin_date: beginDate.toISOString(true),
        end_date: endDate.toISOString(true),
        renewal_date: renewalDate,
        professional: this.state.professionalUse
      })
    })
    const json = await res.json()
    const pushParamsOnError = this.gtmPushParamsOnError(json)

    if (json.error || !json.payment_intent) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', json.error || 'no payment_intent')
      datalayerPush(pushParamsOnError)

      this.setState({ purchaseError: json.error, loading: false, threedsError: false, errorWithDateRedirect: json.error_with_date_redirect, stripeError: false })

      this.highlightSection('paymentSection')

      return
    }

    if (json.payment_intent.status === 'succeeded') {
      const purchaseResult = await this.proceedPurchase(json)

      if (purchaseResult) {
        moveToConfirmationPage(purchaseResult, history, localePrefix, type)
      }
      return
    }

    if (json.payment_intent.status === 'requires_action') {
      let purchaseResult

      try {
        const stripeResponse = await this.state.stripe.handleNextAction({ clientSecret: json.payment_intent.client_secret })

        if (stripeResponse && stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
          purchaseResult = await this.proceedPurchase(json)
        } else if (stripeResponse.error && stripeResponse.error.code === 'payment_intent_authentication_failure') {
          this.setState({ threedsError: true, purchaseError: false, stripeError: false, errorWithDateRedirect: false })
          this.threedsErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          throw new Error(stripeResponse.error.message)
        } else {
          this.setState({
            threedsError: false,
            purchaseError: false,
            errorWithDateRedirect: false,
            stripeError: translatedErrorMessage(stripeResponse.error, this.props.intl)
          })
          this.stripeErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          throw new Error(stripeResponse.error.message)
        }
      } catch {
        this.setState({ loading: false })
        await this.cancelTransaction(json.billable_id)
        datalayerPush(pushParamsOnError)
      }

      if (purchaseResult) {
        moveToConfirmationPage(purchaseResult, history, localePrefix, type)
      }
    }
  }

  gtmPushParamsOnError = (json) => {
    const park = (json.purchase && json.purchase.park) || {}
    const { offerBuilder } = this.props
    const pushParams = {
      event: 'payment_error',
      ep_vehicle_type: '4W',
      ep_new_customer: `${json.new_customer}`,
      currency: 'EUR',
      value: (offerBuilder.multipleEntries.allowSelected ? offerBuilder.amount.totalWithOptions : offerBuilder.amount.totalWithoutOptions),
      transaction_id: `${json.purchase && json.purchase.booking && json.purchase.booking.id}`,
      item_id: `${park.id}`,
      item_name: park.name,
      up_language: (window._oneparkDatas.up_language || null),
      up_locale: (window._oneparkDatas.up_locale || null)
    }
    if (window._oneparkDatas.up_user_id != null && window._oneparkDatas.up_user_id.length !== 0) pushParams.up_user_id = window._oneparkDatas.up_user_id
    return pushParams
  }

  proceedPurchase = async (json) => {
    const { localePrefix, user } = this.props
    const transactionResult = await fetch(`${localePrefix}/payment/transactions/${json.billable_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ payment_intent_id: json.payment_intent && json.payment_intent.id }),
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    const transactionJson = await transactionResult.json()
    if (transactionResult.ok && transactionJson.purchase) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/success', transactionJson.purchase.id)
      if (window.Cookies.get('new_purchase_confirmation') !== undefined) {
        datalayerPushTransaction(transactionJson.purchase)
        datalayerPushGTM('serverEventTransaction')
        window.ga('send', 'event', 'purchase', 'transaction/success', user.id)
      }
      persistStore(this.props).purge()

      window.scrollTo(0, 0)

      return transactionJson
    } else {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', transactionJson.error)
      datalayerPush(this.gtmPushParamsOnError(json))

      this.setState({ purchaseError: transactionJson.error, errorWithDateRedirect: transactionJson.error_with_date_redirect })
      this.setState({ loading: false })

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth', block: 'start' })

      return false
    }
  }

  cancelTransaction = async (transactionId) => {
    const { localePrefix } = this.props

    fetch(`${localePrefix}/payment/transactions/${transactionId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.setState({ loading: false })
  }

  handleMultipleEntriesChange = (event) => {
    const optionPrice = parseFloat(this.props.offerBuilder.multipleEntries.optionPrice)
    const newOfferAmount = this.getOfferAmount() + (event.target.checked ? optionPrice : -optionPrice)

    this.setState({ isMultipleEntriesOption: event.target.checked, offerAmount: newOfferAmount })
    this.protectFromReload('isMultipleEntriesOption', event.target.checked)
    this.protectFromReload('offerAmount', newOfferAmount)
  }

  getOfferAmount = () => {
    return parseFloat(this.state.offerAmount) || parseFloat(this.props.offerBuilder.amount.totalWithOptions)
  }

  handleShowPaymentForm = () => {
    const { showPaymentForm } = this.state
    this.setState({ showPaymentForm: !showPaymentForm })
  }

  renderTermsLink (type, path) {
    return (
      <a href={path} target='_blank' className='link--secondary'>
        <FormattedMessage id={`purchase.purchase_summary.${type}`} />
      </a>
    )
  }

  show3dsInfo = (event) => { event.preventDefault(); $('#info-3d-secure').modal('show') }

  submitButtonMessageId = () => {
    const narrowMobileScreen = window.innerWidth < 300
    if (narrowMobileScreen) return 'authentication.finalize_short'
    if (this.props.type === 'subscription') return 'authentication.finalize_subscription'
    return 'authentication.finalize'
  }

  render () {
    const { beginDate, discountCode, salesTermsPath, termsPath, endDate, intl, localePrefix, mandatoryInfos,
      offerBuilder, travelInfos, user, provider, mobilePurchaseTunnelPaymentInside, mobilePurchaseTunnel,
      optinDisplaying, partnerName, isDefaultHost, type, renewalDate, previousSteps } = this.props
    const { acceptTerms, showPaymentForm, purchaseError, threedsError, professionalUse, loading, userConsent,
      warningVisible, summaryError, highlightTerms, errorWithDateRedirect, stripeError } = this.state
    const errorParts = purchaseError && errorWithDateRedirect && purchaseError.split('. ')
    const parkUrl = `${localePrefix}/parkings/${offerBuilder.park.id}${type === 'subscription' ? `/?type=${type}` : ''}`
    let breadcrumbWrapperClasses = 'registration__breadcrumb-wrapper--centered-on-tablet-summary' +
      ' registration__breadcrumb-wrapper--no-padding registration__breadcrumb-wrapper--min-height'
    if (previousSteps.length > 0) breadcrumbWrapperClasses = breadcrumbWrapperClasses + ' registration__breadcrumb-wrapper--with-stepper'

    return (
      <>
        <div className='purchase-summary__header-left'>
          <div className='registration__logo registration__logo--blue registration__logo--hidden-on-desktop registration__logo--aligned-with-stepper'>
            <SvgImage svg={LogoSvg} />
          </div>
        </div>

        <div className='react-component react-component--flex react-component--no-margin row'>
          <div className='row purchase-summary__header--right purchase-container h-100p'>
            <div className='purchase-summary purchase-summary--real-summary'>
              <OnRegistration3dSecureErrorDialog />
              <OnPurchase3dSecureErrorDialog />
              <Info3dSecureDialog />
              <DuplicateBookingModal
                handleContinue={() => {
                  this.setState({ loading: true })
                  this.completeBooking()
                }}
                duplicateType={this.state.duplicateType} />
              {warningVisible && <Warning toggleWarning={this.toggleWarning} messageId={summaryError} />}

              <div className='row purchase-summary__header'>
                <RegistrationBreadcrumb previousSteps={previousSteps} activeStepId='purchase' futureSteps={[]}
                  stepperClasses='stepper--no-padding'
                  additionalClasses={breadcrumbWrapperClasses}
                  breadcrumbClasses='registration__breadcrumb--at-summary'
                  insideLogoClasses='registration__logo--inside-breadcrumb' />
                <hr className='purchase-summary__divider purchase-summary__divider--no-shadow purchase-summary__divider--starting-left-visible' />
              </div>

              <div className='purchase-summary__page-title row'>
                <h1>
                  <FormattedMessage id={`purchase.purchase_summary.subscription.title`} />
                </h1>
              </div>

              {
                errorParts &&
                <div className='row purchase-summary__error-message purchase-summary__error-message--orange purchase-summary__error-message--padded'>
                  <p data-automation-id='purchase-summary__payment-error'>
                    <span role='alert'>
                      {`${errorParts[0]}. `}
                    </span>
                    <a href={parkUrl} target='_blank' className='purchase-summary__link'>
                      {errorParts[1]}
                    </a>
                  </p>
                </div>
              }

              <div className='row'>
                <div className='purchase-summary__summary-section col-xs-12 col-sm-4 col-sm-push-8'>
                  <div className='row'>
                    <div className='purchase-summary__offer'>
                      <div className='purchase-summary__offer-title'>
                        <FormattedMessage id='purchase.purchase_summary.offer.title' />
                      </div>
                      <ParkOfferSummary
                        beginDate={beginDate}
                        endDate={endDate}
                        offerBuilder={offerBuilder}
                        park={offerBuilder.park}
                        discountCode={discountCode}
                        showOptionPrice={this.state.isMultipleEntriesOption}
                        offerAmount={this.getOfferAmount()}
                        offerType={type}
                        localePrefix={localePrefix}
                        offerId={offerBuilder.id}
                        optionsPrice={offerBuilder.multipleEntries.optionPrice}
                        handleMultipleEntriesChange={this.handleMultipleEntriesChange}
                        isMultipleEntriesChecked={this.isMultipleEntriesParamSet()}
                        showMultipleEntriesChoice={offerBuilder.multipleEntries.allow} />

                      <div className='purchase-summary__reassurance-panel'>
                        <SummaryReassurancePanel
                          maxCancellationDelay={offerBuilder.maxCancellationDelay}
                          maxModificationDelay={offerBuilder.maxModificationDelay}
                          renewalDate={renewalDate}
                          isSubscription={type === 'subscription'}
                          intl={intl} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='purchase-summary__editable-section col-xs-12 col-sm-8 col-sm-pull-4'>
                  <DiscountPartnerSection />
                  <TravelInformationsSection
                    localePrefix={localePrefix}
                    mandatoryInfos={mandatoryInfos}
                    offerBuilder={offerBuilder}
                    editMode={this.state.travelInfoEditing}
                    travelInfoEditModeSwitcher={this.travelInfoEditModeSwitcher}
                    storagePrefix={this.storagePrefix}
                    travelInfos={this.storedTravelInfos ? JSON.parse(this.storedTravelInfos) : travelInfos}
                    forwardedRef={this.travelInfoSectionRef}
                    formRef={this.travelFormRef}
                    warning={this.state.travelWarning}
                    parkTimezone={offerBuilder.park.timezoneName}
                    protectFromReload={this.protectFromReload}
                    turnoffWarning={() => this.setState({ travelWarning: false })} />
                  <AccountSection
                    localePrefix={localePrefix}
                    user={user}
                    offerBuilder={offerBuilder} />
                  <PaymentSection
                    mobilePurchaseTunnelPaymentInside={mobilePurchaseTunnelPaymentInside}
                    mobilePurchaseTunnel={mobilePurchaseTunnel}
                    billingIdentity={user.billingIdentity}
                    offerBuilder={offerBuilder}
                    localePrefix={localePrefix}
                    handleShowPaymentForm={this.handleShowPaymentForm}
                    showPaymentForm={showPaymentForm}
                    handleSubmitPurchase={this.handleSubmitPurchase}
                    stripePublicKey={this.props.stripePublicKey}
                    provider={provider}
                    forwardedRef={this.paymentSectionRef}
                    warning={this.state.paymentWarning}
                    turnoffWarning={() => this.setState({ paymentWarning: false })}
                    handleStripeChange={(complete) => this.setState({ stripeCompleted: complete })}
                    handleStripeError={() => this.setState({
                      threedsError: false,
                      purchaseError: false,
                      errorWithDateRedirect: false
                    })}
                    green
                  />

                  {
                    stripeError &&
                    <div className='row' ref={this.stripeErrorRef}>
                      <div className='purchase-summary__error-message purchase-summary__error-message--orange'>
                        <p data-automation-id='purchase-summary__payment-error' className='purchase-summary__payment-error'>
                          {stripeError}
                        </p>
                      </div>
                    </div>
                  }

                  {user.billingIdentity &&
                    <>
                      <div className='row'>
                        {threedsError && !purchaseError &&
                          <div className='purchase-summary__error-message purchase-summary__error-message--orange' ref={this.threedsErrorRef}>
                            <p data-automation-id='purchase-summary__threeds-error' className='purchase-summary__threeds-error'>
                              <small role='alert'>
                                <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.payment_before_link' />
                                <a className='secure-3d-link' onClick={show3dsErrorOnPurchase}>
                                  <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.link_title' />
                                </a>
                              </small>
                            </p>
                          </div>
                        }
                        <div>
                          {!threedsError && !purchaseError &&
                            <p className='purchase-summary__3ds-text'>
                              <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.before_link' />
                              <a href={'#'} onClick={this.show3dsInfo}>
                                <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.link_text' />
                              </a>
                              <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.after_link' />
                            </p>}
                        </div>

                        {purchaseError && !errorWithDateRedirect &&
                          <div className='row'>
                            <div className='purchase-summary__error-message purchase-summary__error-message--orange purchase-summary__error-message--padded'>
                              <p data-automation-id='purchase-summary__payment-error' className='purchase-summary__payment-error'>
                                {purchaseError}
                              </p>
                            </div>
                          </div>
                        }
                      </div>
                    </>
                  }

                  {!user.billingIdentity && !mobilePurchaseTunnelPaymentInside &&
                    <div className='row' ref={this.footerRef}>
                      <button onClick={this.handleSubmitPurchase} className='btn btn-primary registration__btn-form'
                        data-automation-id='purchase-summary__save-button--no-identity'>
                        <FormattedMessage id='authentication.continue' />
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
            {user.billingIdentity &&
              <div className='purchase-summary__button-container' ref={this.footerRef}>
                <hr className='purchase-summary__divider' />

                <div className='purchase-summary__checks-wrapper'>
                  <div className='purchase-summary__professional-use'>
                    <div className='purchase-summary__checkbox purchase-summary__checkbox--padded-left'>
                      <input id='professional_use'
                        data-automation-id='purchase-summary__professional-use'
                        className='checkbox-op'
                        type='checkbox'
                        checked={professionalUse}
                        onChange={(event) => {
                          this.setState({ professionalUse: event.target.checked })
                          this.protectFromReload('professionalUse', event.target.checked)
                        }} />
                      <label htmlFor='professional_use'>
                        <FormattedMessage id='purchase.purchase_summary.is_professional_use' />
                      </label>
                    </div>
                  </div>

                  <div className={`purchase-summary__optin_consent ${optinDisplaying ? '' : 'hidden'}`}>
                    <div className='purchase-summary__checkbox purchase-summary__checkbox--padded-left'>
                      <input id='optin_consent'
                        data-automation-id='purchase-summary__optin_consent'
                        className='checkbox-op'
                        type='checkbox'
                        checked={userConsent}
                        onChange={(event) => this.setState({ userConsent: event.target.checked })} />
                      <label htmlFor='optin_consent'>
                        <FormattedMessage id='purchase.purchase_summary.accept_providing_personal_info'
                          values={{ partnerName: partnerName }} />
                      </label>
                    </div>
                  </div>

                  <div className='purchase-summary__accept-terms'>
                    <div className='purchase-summary__checkbox purchase-summary__checkbox--padded-left'>
                      <input id='accept_terms'
                        data-automation-id='purchase-summary__accept-terms'
                        className='checkbox-op'
                        type='checkbox'
                        checked={acceptTerms}
                        onChange={(event) => {
                          this.setState({ acceptTerms: event.target.checked, highlightTerms: !event.target.checked })
                          this.protectFromReload('acceptTerms', event.target.checked)
                        }} />
                      <label htmlFor='accept_terms'>
                        <FormattedMessage
                          id={`purchase.purchase_summary.${isDefaultHost ? 'france_' : ''}accept_terms`}
                          values={{
                            salesTerms: this.renderTermsLink('sales_terms', salesTermsPath),
                            terms: this.renderTermsLink('terms', termsPath)
                          }}
                        />
                      </label>
                    </div>
                    {highlightTerms &&
                      <>
                        <label className='purchase-summary__warning--orange'
                          data-automation-id='purchase-summary__terms-warning'>
                          <FormattedMessage id='purchase.purchase_summary.must_accept_terms' />
                        </label>
                      </>}
                  </div>
                </div>
                <div className='purchase-summary__button-wrapper'>
                  <button
                    onClick={this.handleSubmitPurchase}
                    className='purchase-summary__button btn btn-primary registration__btn-form'
                    type='submit'
                    disabled={loading}
                    data-automation-id='purchase-summary__save-button'
                  >
                    {loading && <Loader overlay white />}
                    <span className={loading ? 'invisible' : ''}>
                      <FormattedMessage id={this.submitButtonMessageId()} />
                    </span>
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    previousSteps,
    discountCode,
    mandatoryInfos, travelInfos,
    partnerCard,
    offer: { beginDate, endDate, renewalDate, offerBuilder },
    salesTermsPath
  },
  resources: {
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    localePrefix,
    termsPath,
    isDefaultHost
  },
  search: { params: { type } },
  user,
  optinOptions: { userConsent, partnerName, optinDisplaying }
}) => {
  const storagePrefix = offerBuilder.id
  const storedTravelInfos = sessionStorage.getItem(`${storagePrefix}-travelInfos`)
  const resultingTravelInfos = storedTravelInfos ? JSON.parse(storedTravelInfos) : travelInfos

  return ({
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    isDefaultHost,
    beginDate,
    discountCode,
    salesTermsPath,
    termsPath,
    endDate,
    renewalDate,
    localePrefix,
    mandatoryInfos,
    offerBuilder,
    partnerCard,
    travelInfos: resultingTravelInfos,
    type,
    user,
    userConsent,
    partnerName,
    optinDisplaying,
    previousSteps
  })
}

export default withRouter(injectIntl(connect(mapStateToProps)(Summary)))
